import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { Card } from 'components/Card';
import { ImagePosition } from 'components/Card/Card';
import { Colors } from 'enums/Colors';

import '@splidejs/react-splide/css/core';

import styles from './Magazine.module.scss';

export interface MagazineArticleCard {
  title: string;
  subtitle: string;
  introText: string;
  src: string;
  href: string;
}

interface Props {
  articles: MagazineArticleCard[];
}

export const Magazine: React.FC<Props> = ({ articles }) => (
  <div className={styles.magazine}>
    <h2 className={styles.h2}>Från redaktionen</h2>
    <div className={styles.carousel}>
      <Splide
        options={{
          arrows: false,
          autoWidth: false,
          pagination: false,
          perPage: 3,
          gap: '1rem',
          breakpoints: {
            480: {
              perPage: 1,
              padding: { right: '1.5rem', left: '1.5rem' },
            },
            1144: {
              perPage: 2,
              padding: { right: '1.5rem', left: '1.5rem' },
            },
          },
        }}
        aria-label="Articles"
        className={styles.slider}
      >
        {articles?.map((article) => (
          <SplideSlide key={article.title}>
            <Card
              title={article.title}
              description={article.introText}
              imagePosition={ImagePosition.Top}
              color={Colors.SkyBlue}
              href={article.href}
              imageSrc={article.src}
              action="Läs mer"
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  </div>
);
