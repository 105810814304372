import { UserReviewVariant } from 'components/UserReview/UserReview';

export const reviews = {
  title: 'Våra kunder tycker att vi är...',
  reviews: [
    {
      title: 'Blev rekommenderad Blipp av en bilhandlare',
      text: 'Blev rekommenderad Blipp av en bilhandlare jag gjort affärer med tidigare. Vi hade en spretig bilpark med ett flertal olika finansbolag inblandade. Vi kände att vi ville se över våra bilkostnader och få en samlad blick över avtalen. Vi skickade in en förfrågan via hemsidan och blev dagen efter kontaktad av Daniel de Bruin. Han har från första kontakt varit otroligt proffsig och återkopplat inom 1 timme vid varje kontakt. Nu 1 månad senare har vi 10 bilar i samma avtal och sänkt våra fasta bilkostnader med 30 000:- per månad. Helt otroligt!',
      name: 'Martin Eriksson',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Fantastiskt bra och trygg upplevelse!',
      text: 'Att sälja bilen tycker jag annars är krångligt och jobbigt. Men nu gick försäljningen av vår fina Renault Clio hur bra som helst! Både jag och köparen var nöjda och vi fick hjälp både innan och under köpet. Jag kände mig väldigt trygg som säljare och det var riktigt enkelt och smidigt att sälja! Kan varmt rekommendera Blipp!',
      name: 'Nicolas Waern',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Första gången vi säljer en bil privat',
      text: 'Första gången vi säljer en bil privat och vi var supernervösa. Men affären var otrolig smärtfri och det kändes nästan för bra för att vara sant. Kontakten på Blipp var otrolig (tack Filip), allt var pedagogiskt och kändes superseriöst. Vilket är viktigt vid bilaffären då det oftast handlar om väldigt mycket pengar. Jag fick mina pengar och köparen fick med sig bilen vid mötet. Tack, det är absolut en tjänst jag rekommenderar och skulle vilja använda igen.',
      name: 'Robin',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Funkade perfekt, trygghet för mig som köpare!',
      text: 'Jag var och tittade på en bil jag hittade via Facebook köp och sälj som jag var intresserad utav men såg att det fanns finansiering på den, jag föreslog att använda blipp för mitt köp och säljaren accepterade, funkade utmärkt med digitalt köpkontrakt, säker betalning, lösning av restskuld, bra med en prova på försäkring under 2 veckor för att leta bästa lösning. Mycket prisvärt och en ökad trygghet för mig.',
      name: 'Johan Leijon',
      rating: 5,
      variant: UserReviewVariant.List,
    },
  ],
};

export const releaseReviews = {
  title: 'Våra kunder tycker att vi är...',
  reviews: [
    {
      title: 'Det gick lätt och smidigt',
      text: 'Det gick lätt och smidigt, vi har nu nästan halverat våra bilkostnader vilket känns bra i dessa tider.',
      name: 'Kenth',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Bra bemötande av säljaren',
      text: 'Kort, koncist, enkelt och professionellt. Sänkte min månadskostnad med 40%.',
      name: 'Ted',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'De hjälpte mig',
      text: 'De hjälpte mig att nästan halvera mina kostnader för leasing.',
      name: 'Robert',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Leasing av bilar',
      text: 'Edward på Blipp hjälpte mig lägga om mina leasingavtal på mina företagsbilar, supernöjd då det gick väldigt smidigt och enkelt!',
      name: 'Amandus',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Ett bland dom bättre samtal jag fått',
      text: 'Ett bland dom bättre samtal jag fått, sänkte månadskostnaden rejält. Smidiga att ha att göra med och svarar snabbt när man ringer tillbaka sånt uppskattas rejält.',
      name: 'Ludvig',
      rating: 5,
      variant: UserReviewVariant.List,
    },
    {
      title: 'Rekommenderas!',
      text: 'Anders var smidig och hjälpsam. Allt sköttes smärtfritt och smidigt. Kanon! Sen får man inte glömma att vi sänkte månadskostnaden rejält.',
      name: 'Peter',
      rating: 5,
      variant: UserReviewVariant.List,
    },
  ],
};
