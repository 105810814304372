import React, { CSSProperties } from 'react';
import Image from 'next/image';

import styles from './BackgroundImage.module.scss';

interface Props {
  src: string;
  alt: string;
  style?: CSSProperties;
  priority?: boolean;
}

/**
 * For use in other components. The parent needs to have position:relative set.
 * @constructor
 */
export const BackgroundImage: React.FC<Props> = ({ src, alt, style, priority }) => (
  <div className={styles.bg} style={style}>
    <Image alt={alt} src={src} layout="fill" quality={90} objectFit="cover" priority={priority} />
  </div>
);
