import React from 'react';

import { trustpilot } from 'icons/logo';
import { Icon, IconType } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';

import styles from './UserReview.module.scss';

export enum UserReviewVariant {
  List = 'list',
  Card = 'card',
}

export interface UserReviewProps {
  rating?: number;
  title: string;
  text: string;
  name: string;
  variant: UserReviewVariant;
}

export const UserReview: React.FC<UserReviewProps> = ({
  rating = 5,
  title,
  text,
  name,
  variant,
}) => (
  <div className={`${styles.wrapper} ${styles[variant]}`}>
    <div className={styles.stars}>
      {[...Array(rating)].map((_, i) => (
        <Icon
          // eslint-disable-next-line react/no-array-index-key
          key={title + i}
          icon={trustpilot}
          size={24}
          type={IconType.Fill}
          color={Colors.DarkBlue}
        />
      ))}
    </div>
    <div className={styles.review}>
      <div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <span>{name}</span>
    </div>
  </div>
);
