import React from 'react';

import styles from './Gutter.module.scss';

interface Props {
  size?: 'negative' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
}

export const Gutter: React.FC<Props> = ({ size = 'medium', children }) => (
  <div className={`${styles[size]}`}>{children}</div>
);
