import React from 'react';
import Link from 'next/link';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { UserReview, UserReviewProps, UserReviewVariant } from 'components/UserReview/UserReview';
import Trustpilot from 'public/assets/logotype/trustpilot.svg';
import useMediaQuery from 'utils/useMediaQuery';
import Symbol from 'assets/logotype/symbol.svg';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { Icon } from 'components/tokens/Icon';
import { arrowRight, touch } from 'icons/default';
import { Colors } from 'enums/Colors';

import styles from './Reviews.module.scss';
import '@splidejs/react-splide/css/core';

interface Props {
  title: string;
  reviews: UserReviewProps[];
}

export const Reviews: React.FC<Props> = ({ title, reviews }) => {
  const isMobile = useMediaQuery('(max-width: 992px)');

  return (
    <div className={styles.wrapper}>
      {!isMobile && <Symbol className={styles.symbol} />}

      <div className={styles.inner}>
        <span className={styles.headline}>{title}</span>
        <div>
          <div className={styles.carousel}>
            <Splide
              options={{
                type: 'loop',
                autoplay: true,
                pauseOnHover: true,
                pauseOnFocus: false,
                rewind: false,
                arrows: false,
                autoWidth: false,
                pagination: false,
                perPage: 2,
                gap: '1rem',
                breakpoints: {
                  680: {
                    perPage: 1,
                  },
                  1144: {
                    perPage: 2,
                  },
                },
              }}
              aria-label="reviews"
              className={styles.slider}
            >
              {reviews.map((review) => (
                <SplideSlide key={review.title}>
                  <UserReview
                    key={review.title}
                    variant={UserReviewVariant.List}
                    title={review.title}
                    text={review.text}
                    name={review.name}
                    rating={review.rating}
                  />
                </SplideSlide>
              ))}
            </Splide>
            <div className={styles['icon-touch-wrapper']}>
              <Icon icon={touch} color={Colors.DarkBlue} size={40} />
            </div>
            <Link href="https://se.trustpilot.com/review/blipp.se">
              <a target="_blank">
                <Trustpilot className={styles.icon} />
              </a>
            </Link>
          </div>
          <div className={styles.button}>
            <Button
              type={ButtonType.Button}
              trailingIcon={arrowRight}
              size={ButtonSize.Large}
              variant={ButtonVariant.Ghost}
              href="https://se.trustpilot.com/review/blipp.se"
            >
              Läs fler omdömen
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
