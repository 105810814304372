import React from 'react';
import Link from 'next/link';

import { Colors } from 'enums/Colors';
import { BackgroundImage } from 'components/BackgroundImage';
import { arrowRight } from 'icons/default';
import { Icon, IconType } from 'components/tokens/Icon';
import Symbol from 'assets/logotype/symbol.svg';

import styles from './Card.module.scss';

export enum ImagePosition {
  Top = 'top',
  None = 'none',
  Stretch = 'stretch',
  Bottom = 'bottom',
}

interface CardProps {
  title: string;
  subtitle?: string;
  description?: string;
  action?: string;
  imageSrc?: string;
  imagePosition: ImagePosition;
  color: Colors;
  buttonColor?: Colors;
  href: string;
  type?: string;
  symbol?: boolean;
  bulletList?: {
    title: string;
    icon: any;
  }[];
}

export const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  description,
  action,
  imageSrc,
  imagePosition,
  color,
  buttonColor,
  href,
  symbol = false,
  bulletList,
  type,
}) => (
  <Link href={href}>
    <a
      className={`${styles.card} ${styles[imagePosition]} ${styles[type || '']}`}
      style={{ backgroundColor: `var(--color-${color})` }}
    >
      {symbol && <Symbol className={`${styles.symbol} ${styles[color]}`} />}
      {imageSrc &&
        (imagePosition === ImagePosition.Top || imagePosition === ImagePosition.Stretch) && (
          <div className={styles['image-container']}>
            <BackgroundImage src={imageSrc} alt={title} />
          </div>
        )}
      <div className={`${styles.content} ${styles[color]}`}>
        <div>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </div>
        {description && <p>{description}</p>}
        {bulletList &&
          bulletList.map((bullets) => (
            <div className={`${styles.bulletList} ${styles[color]}`}>
              <Icon
                size={20}
                icon={bullets.icon}
                color={color === Colors.DarkBlue ? Colors.White : Colors.DarkBlue}
                type={IconType.Stroke}
              />
              {bullets.title}
            </div>
          ))}
      </div>
      {imageSrc && imagePosition === ImagePosition.Bottom && (
        <div className={styles['image-container']}>
          <BackgroundImage src={imageSrc} alt={title} />
        </div>
      )}
      {action && (
        <div
          className={`${styles.action} ${styles[buttonColor || color]}`}
          style={{
            backgroundColor: `var(--color-${buttonColor || color})`,
            borderColor: `var(--color-${
              color === Colors.DarkBlue ? Colors.White : Colors.DarkBlue
            })`,
          }}
        >
          {action}
          <Icon
            size={18}
            icon={arrowRight}
            color={
              buttonColor === Colors.DarkBlue || color === Colors.DarkBlue
                ? Colors.White
                : Colors.DarkBlue
            }
            type={IconType.Stroke}
          />
        </div>
      )}
    </a>
  </Link>
);
